@font-face {
  font-family: 'SF Pro Rounded';
  src: url(./fonts/SF-Pro-Rounded-Thin.otf);
  font-weight: 100;
}
@font-face {
  font-family: 'SF Pro Rounded';
  src: url(./fonts/SF-Pro-Rounded-Ultralight.otf);
  font-weight: 200;
}
@font-face {
  font-family: 'SF Pro Rounded';
  src: url(./fonts/SF-Pro-Rounded-Light.otf);
  font-weight: 300;
}
@font-face {
  font-family: 'SF Pro Rounded';
  src: url(./fonts/SF-Pro-Rounded-Regular.otf);
  font-weight: 400;
}
@font-face {
  font-family: 'SF Pro Rounded';
  src: url(./fonts/SF-Pro-Rounded-Medium.otf);
  font-weight: 500;
}
@font-face {
  font-family: 'SF Pro Rounded';
  src: url(./fonts/SF-Pro-Rounded-Semibold.otf);
  font-weight: 600;
}
@font-face {
  font-family: 'SF Pro Rounded';
  src: url(./fonts/SF-Pro-Rounded-Bold.otf);
  font-weight: 700;
}
@font-face {
  font-family: 'SF Pro Rounded';
  src: url(./fonts/SF-Pro-Rounded-Black.otf);
  font-weight: 800;
}
@font-face {
  font-family: 'SF Pro Rounded';
  src: url(./fonts/SF-Pro-Rounded-Heavy.otf);
  font-weight: 900;
}

body {
  background-color: #fff !important;
  color: #242424;
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-family: 'SF Pro Rounded', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #b5b9c7;
  border-radius: 3px;
}
